<template>
  <v-container>
    <v-card class="pa-2">
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchUser"
          append-icon="fas fa-search"
          label="Pesquisa"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-btn color="primary" class="ml-5" icon @click="dialogs.newUser = true"
          ><v-icon>fas fa-user-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-data-table
        style="cursor: pointer;"
        :headers="tableUsers.headers"
        :items="users"
        item-key="id"
        class="elevation-1"
        @click:row="showDialogEditUser"
        :search="searchUser"
      ></v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.newUser" max-width="500">
      <v-card>
        <v-card-title class="headline">Novo usuário</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="newUser.name"
                label="Nome (usuário)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="newUser.email"
                label="Email"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="newUser.access_level"
                :items="itemsAccessLevel"
                item-value="id"
                item-text="text"
                label="Nível de acesso"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newUser.password"
                :append-icon="passShow ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :type="passShow ? 'text' : 'password'"
                name="password"
                label="Senha"
                hint="Indique a nova senha"
                @click:append="passShow = !passShow"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogs.newUser = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="createNewUser()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.editUser" max-width="500">
      <v-card>
        <v-card-title class="headline">Editar usuário</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="editUser.name"
                label="Nome (usuário)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="editUser.email"
                label="Email"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="editUser.access_level"
                :items="itemsAccessLevel"
                item-value="id"
                item-text="text"
                label="Nível de acesso"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="[
                  { text: 'Loja', value: 'name' },
                  { text: 'Novos preços', value: 'last_sync_news' }
                ]"
                :items="editUser.stores"
                hide-default-footer
                item-key="id"
                class="elevation-1"
              ></v-data-table>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editUser.password"
                :append-icon="passShow ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :type="passShow ? 'text' : 'password'"
                name="password"
                label="Nova senha"
                hint="Indique a nova senha"
                @click:append="passShow = !passShow"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogs.editUser = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="updateUser()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  components: {},
  data() {
    return {
      me: {},
      passShow: false,
      tableUsers: {
        headers: [
          { text: 'Nome', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Nível de acesso', value: 'access_level' }
        ]
      },
      itemsAccessLevel: [
        { id: 777, text: '[Admin] Acesso completo ao sistema' },
        {
          id: 600,
          text: '[Editor] Pode editar e criar novos cadastros'
        },
        { id: 1, text: '[Padrão] Usuário simples' }
      ],
      dialogs: {
        newUser: false,
        editUser: false
      },
      newUser: {},
      editUser: {},
      searchUser: '',
      users: []
    }
  },
  methods: {
    getMe() {
      var vm = this
      vm.$API
        .Request('get', `me`)
        .then(result => {
          console.debug(result)
          vm.me = result
        })
        .catch(error => {
          console.debug(error)
        })
    },
    fetchUsers() {
      var vm = this
      vm.$API
        .Request('get', `user/list`)
        .then(result => {
          console.debug(result)
          if (result && result.data) {
            vm.users = result.data
          }
        })
        .catch(error => {
          console.debug(error)
          if (error && error.message) {
            vm.$snotify.error(error.message)
          }
        })
    },
    updateMe() {
      var vm = this
      vm.$API
        .Request('post', `me/update`, vm.me)
        .then(result => {
          console.debug(result)
          if (result && result.message) {
            if (result.code == 200) {
              vm.$snotify.success(result.message)
            } else {
              vm.$snotify.warning(result.message)
            }
          }
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    showDialogEditUser(_user) {
      var vm = this
      vm.editUser = _user
      vm.dialogs.editUser = true
    },
    updateUser() {
      var vm = this
      vm.$API
        .Request('post', `user/update/${vm.editUser.id}`, vm.editUser)
        .then(result => {
          console.debug(result)
          if (result && result.message) {
            if (result.code == 200) {
              vm.$snotify.success(result.message)
              vm.fetchUsers()
              vm.editUser = {}
            } else {
              vm.$snotify.warning(result.message)
            }
          }
          vm.dialogs.editUser = false
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.$snotify.error(error.message)
          if (error && error.response && error.response.data) {
            if (error.response.data.message) {
              vm.$snotify.error(error.response.data.message)
            }
            if (error.response.data.email) {
              error.response.data.email.forEach(msg => {
                vm.$snotify.error(msg)
              })
            }
            if (error.response.data.password) {
              error.response.data.password.forEach(msg => {
                vm.$snotify.error(msg)
              })
            }
          }
        })
    },
    createNewUser() {
      var vm = this
      vm.$API
        .Request('post', `user/new`, vm.newUser)
        .then(result => {
          console.debug(result)
          if (result && result.message) {
            if (result.code == 200) {
              vm.$snotify.success(result.message)
              if (result.data) {
                vm.users.push(result.data)
              }
              vm.newUser = {}
            } else {
              vm.$snotify.warning(result.message)
            }
          }
          vm.dialogs.newUser = false
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.$snotify.error(error.message)
          if (error && error.response && error.response.data) {
            if (error.response.data.message) {
              vm.$snotify.error(error.response.data.message)
            }
            if (error.response.data.email) {
              error.response.data.email.forEach(msg => {
                vm.$snotify.error(msg)
              })
            }
            if (error.response.data.password) {
              error.response.data.password.forEach(msg => {
                vm.$snotify.error(msg)
              })
            }
          }
        })
    }
  },
  created() {
    var vm = this
    vm.fetchUsers()
  },
  mounted() {}
}
</script>
